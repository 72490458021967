import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from "@material-ui/core/Typography/Typography"
import FileDownloadCard from "../../common/components/FileDownloadCard"
import identity from "crocks/combinators/identity"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"

// file IDs from the server
const PORTAL_SLIDES = 4
const ACCIDENT_WORKSHOP_SLIDES = 9
const SIMULATION_READY_DATASET = 11
const SEMINAR_ZIP_FILE = 19
const DRIVER_REENGAGEMENT_SLIDES = 20

const styles = theme => ({
  root: {},
  list: {
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      paddingTop: theme.spacing(2),
    }
  },
  downloads: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    }
  },
})

class CommunitySection extends React.PureComponent {
  static propTypes = {
    onDownload: PropTypes.func,
  }

  static defaultProps = {
    user: {},
    onDownload: identity,
    onSteeringDownload: identity,
  }

  handlePortalClick = () => this.props.onDownload(PORTAL_SLIDES)
  handleSimulationDatasetClick = () => this.props.onDownload(SIMULATION_READY_DATASET)
  handleAccidentWorkshopSlidesClick = () => this.props.onDownload(ACCIDENT_WORKSHOP_SLIDES)
  handleDriverReengagementSlidesClick = () => this.props.onDownload(DRIVER_REENGAGEMENT_SLIDES)
  handleSeminarZipClick = () => this.props.onDownload(SEMINAR_ZIP_FILE)

  render() {
    const { classes } = this.props

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h5">Community</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.list}>
          <Typography>
            These resources are provided by and for the AMP Portal community.
          </Typography>

          <div className={classes.downloads}>
            <FileDownloadCard
              fileType=".PDF"
              title="AMP Portal & Data Slides"
              description="Slides presented at the portal and data workshop"
              onClick={this.handlePortalClick}
            />
            <FileDownloadCard
              fileType=".PDF"
              title="Simulation-Ready Dataset Overview"
              description="Process overview for simulation-ready dataset (augmented layer) implementation"
              onClick={this.handleSimulationDatasetClick}
            />
            <FileDownloadCard
              fileType=".PDF"
              title="US Accident Data Workshop Slides"
              description="Slide deck from the accident data workshop"
              onClick={this.handleAccidentWorkshopSlidesClick}
            />
            <FileDownloadCard
                fileType=".PDF"
                title="Driver Re-engagement Slides"
                description="Slides with background details for the Driver Re-engagement package"
                onClick={this.handleDriverReengagementSlidesClick}
            />
            <FileDownloadCard
              fileType=".ZIP"
              title="AMP Data and Portal Seminar 2022"
              description="Materials presented during the AMP Data and Portal Seminar 11/30/2022 – 12/1/2022"
              onClick={this.handleSeminarZipClick}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(CommunitySection)

