import React from 'react'
import { Link } from 'react-router-dom'
import { Hidden, Typography, withStyles } from "@material-ui/core/"

const styles = theme => ({
  root: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: '0.25em 0',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightLight,

    '& a': {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
      fontWeight: 'bolder',
      margin: '0 1em',

    },
    '& a:hover': {
      textDecoration: 'underline',
    }
  }
})

const Footer = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.link}>
          <Link to="/doc/contact">Contact Us</Link>
          <Link to="/doc/privacy">Privacy Policy</Link>
        </div>
      </Hidden>
      <Typography variant="caption" color="primary">
        &copy; 2018 - 2025 Virginia Tech Transportation Institute
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Footer)